import {Module} from "./module.model";
import {MenuMode} from "./menu_mode.model";
import {Grid} from "./grid.model";
import {Action} from "./action.model";
import {Type} from "./type.model";
import {SiteModule} from "./site_module.model";

export class ChildSiteModule {
  id: number;
  size: string;
  click_type: Type;
  child_type: Type;
  route: [string];

  constructor(datas) {
    Object.assign(this, datas);
    this.click_type = new Type(this.click_type);
  }
}
