export class AuthModel {
  token: string;
  // refreshToken: string;
  expiration: Date;

  setAuth(auth: any) {
    this.token = auth.token;
    // this.refreshToken = auth.refreshToken;
    this.expiration = auth.expiration;
  }
}
