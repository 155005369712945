import {ValueValue} from './value_value.model';
import {Functions} from "../_functions/functions";
import {Type} from "./type.model";

export class Value {
  value: ValueValue;
  search: string;
  sort: string;
  filter: {};
  before: ValueValue[] = [];
  after: ValueValue[] = [];
  actions: {} = {};

  constructor(datas) {
    Object.keys(datas).forEach(key => {
      switch (key) {
        case 'value':
          this.value = new ValueValue(datas[key]);
          if (datas['type']) {
            this.value.type = new Type(datas['type']);
          }
          break;
        case 'search':
          this.search = Functions.slugify(datas[key], true).toLowerCase();
          break;
        case 'sort':
          this.sort = Functions.slugify(datas[key], true).toLowerCase();
          break;
        case 'filter':
          alert('to do');
          break;
        case 'before':
          console.log(datas, key);
          datas[key].forEach(data => {
            this.before.push(new ValueValue(data));
          });
          break;
        case 'after':
          datas[key].forEach(data => {
            this.after.push(new ValueValue(data));
          });
          break;
        default:
          console.warn('Not managed: ' + key);
      }
    });
  }

  /* ------------------------------------------------------------------------------------------------------------------ */

  delete() {
    this.value = null;
    this.before = null;
    this.after = null;
    this.actions = null;
  }
}
