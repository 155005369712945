import { ValueClass } from './value_class.model';
import {Type} from "./type.model";

export class ValueValue {
    value;
    url: string;
    type: Type;
    order: number;
    classes: ValueClass[];
    predefined_classes: ValueClass[];
    //
    class_to_string: string = '';

    constructor(datas) {
        Object.assign(this, datas);
        // CLASSES
        datas.classes?.forEach(cl => {
            this.class_to_string += cl + ' ';
        });
    }

    // public hasClass(c: string) {
    //     for (let i = 0; i < this.predefined_classes.length; i++) {
    //         if (this.predefined_classes[i].class === c) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }
    // public getClass(c: string): DetailValueClass {
    //     for (let i = 0; i < this.predefined_classes.length; i++) {
    //         if (this.predefined_classes[i].class === c) {
    //             return this.predefined_classes[i];
    //         }
    //     }
    //     return new DetailValueClass();
    // }
}
