import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {Refresh} from "../_models/refresh.model";
import {Element} from "../_models/element.model";

@Injectable({
  providedIn: 'root'
})
export class BubbleService {
  bubbles: BehaviorSubject<number>[] = [];

  constructor() {}

  get(ressource_id: string): Observable<number> {
    if (!this.bubbles[ressource_id]) {
      this.bubbles[ressource_id] = new BehaviorSubject<number>(null);
    }
    return this.bubbles[ressource_id];
  }
  update(ressource_id: string, nb: number) {
    console.log('UPDATE BUBBLE #' + ressource_id + ' > ' + nb);
    if (!this.bubbles[ressource_id]) {
      this.bubbles[ressource_id] = new BehaviorSubject<number>(null);
    }
    this.bubbles[ressource_id].next(nb);
  }

  updateSiteModuleBubble(element: Element) {
    setTimeout(() => { // SET TIMEOUT BECAUSE OF EXPRESSION CHANGE AFTER CHECK
      element.bubbles_for_sitemodule.forEach(sitemodule => {
        this.update('sitemodule.' + sitemodule['id'], element.list_type ? element.list?.rows?.length : element.grid_list?.rows?.length);
      });
    }, 1000);
  }
}
