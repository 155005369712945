import {Element} from "./element.model";
import {GridListRow} from "./grid_list_row.model";

export class GridList {
  rows: GridListRow[] = [];
  rows_filtered: GridListRow[] = [];
  // SEARCH
  search_str = '';
  search_columns: string[] = [];
  // SORT
  sort_columns: {} = {};
  // FILTER
  // filter: boolean = false;
  // filters = new UntypedFormGroup({});
  // show_filter: boolean = false;
  // PAGINATION
  page_number: number = 1;
  nb_by_page: number;
  nb_by_page_default: number = 25;
  page_interval: number[] = [];

  constructor(rows: GridListRow[], element: Element) {
    // this.element = element;
    // PARAMS
    this.nb_by_page = element?.pagination === 0 ? 0 : element?.pagination ?? this.nb_by_page_default;
    // ROWS
    rows?.forEach(row => {
      this.addRow(row, element);
    });
    // console.log(this.rows);
    // console.log(this.rows_filtered);
    // FILTERS // TODO
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  nbPages() {
    return Math.ceil(this.rows_filtered.length / this.nbByPageTraduction());
  }
  firstAndLastElement() {
    return [(this.nbPages() - 1) * this.nbByPageTraduction()];
  }
  nbByPageTraduction() {
    return this.nb_by_page > 0 ? this.nb_by_page : this.rows_filtered.length;
  }
  updateNbByPage(nb: number) {
    this.nb_by_page = nb;
    this.updatePageInterval(true);
  }
  updatePageNumber(nb: number) {
    this.page_number = nb;
    this.updatePageInterval();
  }
  updatePageInterval(from_nbByPage: boolean = false) {
    this.page_interval = [];
    for (let i = this.page_number - (this.page_number > this.nbPages() - 2 ? 3- (this.nbPages() - this.page_number) : 1); i < this.page_number + (this.page_number < 4 ? 5 - this.page_number : 2); i++) {
      if (i < 2 || i >= this.nbPages()) {
        continue;
      }
      this.page_interval.push(i);
    }
    // CHECK PAGE vs NUMBER OF PAGE
    if (!this.page_number) {
      this.page_number = 1;
    } else if (this.page_number > this.nbPages()) {
      this.page_number = from_nbByPage ? this.nbPages() : 1;
    }
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  addRow(row, element: Element, for_construct: boolean = true) {
    // NEW
    let r = new GridListRow(row, element);
    this.rows.push(r);
    if (for_construct) {
      this.rows_filtered.push(r);
    }
  }

  removeRow(row_id: string) {
    const index = this.rows.findIndex(row => row.ressource_id === row_id);
    if (index !== -1) {
      this.rows.splice(index, 1);
      this.rows_filtered.splice(this.rows_filtered.findIndex(row => row.ressource_id === row_id), 1);
    }
  }

  updateRow(row_updated, element: Element) {
    // INDEX
    const index = this.rows.findIndex(row => row?.ressource_id === row_updated.entity_id);
    // UPDATE
    this.rows[index].update(row_updated, element);
  }

  /* ------------------------------------------------------------------------------------------------------------------ */

  delete() {
    //
    this.rows?.forEach(item => {
      item.delete();
    });
    this.rows = null;
    //
    this.rows_filtered?.forEach(item => {
      item.delete();
    });
    this.rows_filtered = null;
  }

}
