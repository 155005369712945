import { NgModule } from '@angular/core';
import {LoaderComponent} from "./loader.component";
import {CommonModule} from "@angular/common";
import {LoaderWrapperOffcanvasComponent} from "./loader_wrapper_offcanvas/loader_wrapper_offcanvas.component";
import {LoaderElementComponent} from "./loader_element/loader_element.component";
import {LoaderRowComponent} from "./loader_row/loader_row.component";
import {ActionModule} from "../../component/action/action.module";
import {LoaderWrapperElementComponent} from "./loader_wrapper_element/loader_wrapper_element.component";
import {LoaderWrapperModalComponent} from "./loader_wrapper_modal/loader_wrapper_modal.component";
import {LoaderWrapperDropdownComponent} from "./loader_wrapper_dropdown/loader_wrapper_dropdown.component";
import {LoaderWrapperPageComponent} from "./loader_wrapper_page/loader_wrapper_page.component";
import {LoaderActionComponent} from "./loader_action/loader_action.component";
import {LoaderWrapperGlobalComponent} from "./loader_wrapper_global/loader_wrapper_global.component";
import {SplashComponent} from "./splash/splash.component";
import {LoaderCacheComponent} from "./loader_cache/loader_cache.component";

@NgModule({
  declarations: [
      LoaderComponent,
      LoaderWrapperDropdownComponent,
      LoaderWrapperElementComponent,
      LoaderWrapperGlobalComponent,
      LoaderWrapperModalComponent,
      LoaderWrapperOffcanvasComponent,
      LoaderWrapperPageComponent,
      LoaderElementComponent,
      LoaderRowComponent,
      LoaderActionComponent,
      LoaderCacheComponent,
      SplashComponent,
  ],
  imports: [
      CommonModule,
  ],
  exports: [
      LoaderComponent,
      LoaderWrapperDropdownComponent,
      LoaderWrapperElementComponent,
      LoaderWrapperGlobalComponent,
      LoaderWrapperModalComponent,
      LoaderWrapperOffcanvasComponent,
      LoaderElementComponent,
      LoaderRowComponent,
      LoaderActionComponent,
      LoaderCacheComponent,
      SplashComponent,
  ],
})
export class LoaderModule {}
