import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import {map, catchError, switchMap, finalize, concatMap, mergeMap, tap} from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { Router } from '@angular/router';
import {environment} from "../../../../environments/environment";
import {RestService} from "../../../_services/rest.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Functions} from "../../../_functions/functions";
import {Structure} from "../../../_models/structure.model";
import {AppService} from "../../../_services/app.service";

const API_URL = `${environment.api_url}user`;
const LOCAL_STORAGE_TOKEN = `SOSF-${environment.version}-${environment.local_storage_token}`;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  auth: AuthModel;
  user: UserModel;
  // IP
  init_need_ip: boolean;
  ip_wan: string;
  ip_lan: string[];
  init$: Observable<any>;
  ip_wan$: Observable<string>;
  ip_lan$: Observable<string[]>;

  constructor(
      private http: HttpClient,
      private router: Router,
  ) {
    // INIT
    this.init$ = this.http.get(API_URL + '/init').pipe(
        tap((response: any) => { this.init_need_ip = response.need_ip })
    );

    // IP WAN
    this.ip_wan$ = this.http.get("https://api.ipify.org/?format=json").pipe(
        tap((response: any) => { this.ip_wan = response.ip })
    );

    // IP LAN
    this.ip_lan$ = new Observable(observer => {
      this.ip_lan = [];
      if (window.RTCPeerConnection) {
        const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
        var myPeerConnection = window.RTCPeerConnection;
        var pc = new myPeerConnection({iceServers:[]})
        var noop = function(){};

        pc.createDataChannel('');
        pc.createOffer().then(function(sdp) {
          pc.setLocalDescription(sdp, noop, noop);
        }).catch(function(reason) {});

        // listen for candidate events
        pc.onicecandidate = function(ice) {
          if (ice && ice.candidate && ice.candidate.candidate && ice.candidate.candidate.match(ipRegex)) {
            ice.candidate.candidate.match(ipRegex).forEach(ip => {
              if (!this.ip_lan.includes(ip)) {
                this.ip_lan.push(ip);
              }
            });
          }
        }.bind(this);
        Functions.completeObservable(observer);

      } else {
        console.log('NO IP');
        Functions.completeObservable(observer);
      }
    });
  }

  ngOnDestroy() {}

  /* ---------------------------------------------------------------------------------------------------------------- */

  login(email: string, password: string, site: string = null, url: string = null): Observable<any> {
    return this.getIp(AuthModel, API_URL + '/connexion', {username: email, password: password, site_id: site, url: url});
  }

  logout() {
    if (this.auth) {
      this.post(API_URL + '/deconnexion', null, true).subscribe(response => {
        if (response['success']) {
          this.resetAuth(response['url']);
        } else {
          this.resetAuth();
        }
      });
    } else {
      this.resetAuth();
    }
  }

  /* ---------------------------------------------------------------------------------------------------------------- */

  setAuth(auth: AuthModel): Observable<any> {
    // SAVE AUTH
    localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(auth));
    // GET USER
    return this.getUser();
  }

  getUser(): Observable<UserModel> {
    this.auth = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TOKEN));
    // GET USER CONNECTED FROM TOKEN
    if (this.auth) {
      // HEADERS
      let headers = new HttpHeaders({
        Authorization: `Bearer ${this.auth.token}`,
      });

      if (this.ip_wan) {
        let headers = new HttpHeaders({Authorization: `Bearer ${this.auth.token}`});
        return this.http.post<UserModel>(API_URL, JSON.stringify({ip_wan: this.ip_wan, ip_lan: JSON.stringify(this.ip_lan)}), { headers }).pipe(
            map((user: UserModel) => {
              if (user) {
                this.user = user;
              } else {
                this.logout();
              }
              return user;
            }));
      }
      else {
        return this.getIp(UserModel, API_URL, {}, { headers: headers }).pipe(
            map((user: UserModel) => {
              if (user) {
                this.user = user;
              } else {
                this.logout();
              }
              return user;
            }));
      }
    }
    return of(null);
  }

  getIp(model, url, params, options = {}): Observable<any> {
    return this.init$.pipe(
        concatMap(() => { return this.init_need_ip ? this.ip_wan$ : of({}) }),
        mergeMap(() => { return this.init_need_ip ? this.ip_lan$ : of({}) }),
        concatMap(() => {
          return this.http.post<{model}>(url, JSON.stringify({...params, ip_wan: this.ip_wan, ip_lan: JSON.stringify(this.ip_lan)}), options);
        }),
    );
  }

  /* ---------------------------------------------------------------------------------------------------------------- */

  post(url: string, body = null, auth: boolean = false) {
    let headers = null;
    if (auth) {
      headers = new HttpHeaders({
        Authorization: `Bearer ${this.auth.token}`,
      });
    }
    let responseType;
    responseType = 'json';

    return this.http.post(url, body, { headers, responseType }).pipe(
        map(response => {
          return response;
        }, error => {
          console.error(`AUTH POST ERROR: ${url}`, error);
        }));
  }

  resetAuth(url: string = '/auth/connexion') {
    // RESET AUTH
    this.auth = null;
    // RESET USER
    this.user = null;
    // REMOVE LOCAL STORAGE
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    // ROUTER
    this.router.navigate([url]);
  }
}
