import {Type} from "./type.model";
import {Regex} from "./regex.model";
import {Action} from "./action.model";
import {SiteModule} from "./site_module.model";
import {Element} from "./element.model";
import {Value} from "./value.model";

export class Detail {
  unique_id: string;
  ressource_id: string;
  //
  id: string;
  label: string;
  placement: string;
  show_label: boolean = true;
  // MANY TO ONE
  type: Type;
  regex: Regex;
  child_site_module: SiteModule;
  // ONE TO MANY
  actions: Action[];
  // CUSTOM
  // entity_id: string;
  values: Value[];
  display: boolean;
  // FILTERS
  search: string;
  sort: string;
  filter_search: boolean;
  filter_sort: boolean;
  // ACTION
  action_active: boolean = false;
  // LOADING
  loading: boolean = false;
  loading_refresh: boolean = false;

  constructor(datas, ressource, entity_id ?) {
    // RESSOURCE ID
    this.ressource_id =  datas['entity_id'] ?? entity_id;
    this.unique_id = 'detail.' +  datas['id'] + (this.ressource_id ? '#' + this.ressource_id : '');
    // this.ressource = ressource;
    // OTHER
    this.update(datas, ressource)
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  update(datas, ressource) {
    delete datas.ressource_id;
    delete datas.unique_id;
    delete datas.ressource;
    Object.assign(this, datas);
    // VALUES
    if (datas.values) {
      this.values = [];
      this.search = null;
      this.sort = null;
      Object.keys(datas.values).forEach(key => {
        this.values.push(new Value(datas.values[key]));
        // SEARCH
        if (datas.values[key].search) {
          if (!this.search) {
            this.search = '';
          }
          this.search += datas.values[key].search + ' ';
        }
        // SORT
        if (datas.values[key].sort) {
          if (!this.sort) {
            this.sort = '';
          }
          this.sort += datas.values[key].sort + ' ';
        }
      });
    }
    // ACTIONS
    this.actions = [];
    datas.actions?.forEach(action => {
      // let action_clone = new Action(action, this.ressource.type.type === 'list' ? this.ressource : this);
      // let action_clone = new Action({...action, entity_id: }, this);
      let action_clone = new Action(action, this);
      // ACTION ABOUT ALL THE LINE / GRID
      // if (((this.ressource.constructor.name === 'Element' && this.ressource['type'].type === 'list') || this.ressource.constructor.name === 'List'  || this.ressource.constructor.name === 'GridList') && this.placement === 'actions') {
      if (this.type?.type === 'actions') {
        action_clone.ressource = ressource;
      }
      // VALUE
      if (datas.values?.actions) {
        action_clone.value = datas.values.actions[action.id] ?? null;
      }
      // PUSH
      this.actions.push(action_clone);
    });
  }

  /* ------------------------------------------------------------------------------------------------------------------ */

  delete() {
    //
    this.type = null;
    this.regex = null;
    this.child_site_module = null;
    //
    this.values?.forEach(item => {
      item.delete();
    });
    this.values = null;
    //
    this.actions?.forEach(item => {
      item.delete();
    });
    this.actions = null;
  }
}
