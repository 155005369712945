import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../_services/app.service";

@Component({
  selector: 'manager-loader-wrapper-offcanvas',
  templateUrl: './loader_wrapper_offcanvas.component.html',
})
export class LoaderWrapperOffcanvasComponent {
  @Input() type: string;
  @Input() wrapper: string;
  //
  @Input() header: boolean;

  constructor(
      public appS: AppService,
  ) {}

}
