import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../_services/app.service";

@Component({
  selector: 'manager-loader-action',
  templateUrl: './loader_action.component.html',
})
export class LoaderActionComponent implements OnInit {
  @Input() type: string;
  @Input() wrapper: string;
  //
  @Input() header: boolean;
  @Input() footer: boolean;

  constructor(
      public appS: AppService,
  ) {}

  ngOnInit() {}

}
