import {Element} from "./element.model";

export class GridBloc {
  size: number;
  elements: Element[];

  constructor(datas) {
    Object.assign(this, datas);
    // ELEMENTS
    if (datas.elements) {
      this.elements = [];
      datas.elements.forEach(e => {
        this.elements.push(new Element(e));
      });
    }
  }

  /* ------------------------------------------------------------------------------------------------------------------ */

  delete() {
    this.elements?.forEach(item => {
      item.delete();
    });
    this.elements = null;
  }
}
