import {Detail} from "./detail.model";
import {AppService} from "../_services/app.service";

export class Navigation {
  route_full: string = null;
  type: string = null;
  wrapper: string = null;
  step: string = null;
  anchor: string = null;
  //
  route: string[] = [];
  anchors = {};

  constructor(datas) {
    this.update(datas);
  }

  /* -------------------------------------------------------------------------------------------------------------- */

  update(datas ?) {
    Object.assign(this, datas);
    // ROUTE
    if (this.route) {
      this.route_full = this.route.join('/');
    }
    // ROUTE FULL
    else if (this.route_full) {
      const parts = this.route_full?.split('#');
      this.route = (parts.shift()).split('/');
      parts.forEach(part => {
        const anchors = part.split('.');
        this.anchors[anchors[0]] = anchors[1];
      });
    }
  }
}
