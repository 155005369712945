import {Field} from './form_field.model';
import {Section} from "./form_section.model";
import {FormType} from "./form_type.model";
import {FormGroup} from "@angular/forms";

export class Form {
  id: string;
  type: FormType;
  fields: Field<any>[] = [];
  sections: Section[] = [];
  values: {};
  //
  form: FormGroup;

  constructor(datas ?) {
    Object.assign(this, datas);
    // TYPE
    this.type = new FormType(this.type);
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  reset() {
    this.fields = [];
    this.sections = [];
    this.values = null;
    this.form = null;
  }
}
