import {Injectable, NgZone} from '@angular/core';
import {IndividualConfig, ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  // TOASTR OPTIONS
  // options: {} = { timeOut: 30000, extendedTimeOut: 30000, tapToDismiss: false, positionClass: 'md-toast-full-top' };
  options_form: {} = { timeOut: 3000, progressBar: true, positionClass: 'toast-top-full-width', toastClass: 'toast-full-width-content', enableHtml: true};
  options_update: {} = { disableTimeOut: true, tapToDismiss: false, closeButton: true, enableHtml: true, positionClass: 'toast-top-right'};
  options_telephony: {} = { disableTimeOut: true, tapToDismiss: false, closeButton: true, enableHtml: true, positionClass: 'toast-top-right'};
  // options_update: {} = { timeOut: 8000, tapToDismiss: false, enableHtml: true, positionClass: 'toast-top-right', toastClass: 'toast-full-width-content'};
  // options_login_success: {} = { positionClass: 'md-toast-full-top', timeOut: 3000 };
  // options_login_error: {} = { positionClass: 'md-toast-full-top' };
  // options_form: {} = { positionClass: 'md-toast-page-top', enableHtml: true, timeOut: 2500 };

  constructor(
    private toastrService: ToastrService,
  ) {}

  show(message: string, title?: string | any, options?, type?: string): any {
    return this.toastrService.show(message, title, options, type);
  }
  success(message: string, title?: string | any, options?): any {
    if (!options || !options['icon']) {
      options['icon'] = 'far fa-check';
    }
    return this.zone('success', title, message, options);
  }
  error(message: string, title?: string | any, options?): any {
    if (!options || !options['icon']) {
      options['icon'] = 'far fa-times';
    }
    return this.zone('error', title, message, options);
  }
  info(message: string, title?: string | any, options?): any {
    if (!options || !options['icon']) {
      options['icon'] = 'far fa-info-circle';
    }
    return this.zone('info', title, message, options);
  }
  warning(message: string, title?: string | any, options?): any {
    if (!options || !options['icon']) {
      options['icon'] = 'far fa-warning';
    }
    return this.zone('warning', title, message, options);
  }

  clear(toastId?: number): void {
    if (toastId) {
      this.toastrService.clear(toastId);
    } else {
      this.toastrService.clear();
    }
  }
  remove(toastId: number): any {
    this.toastrService.remove(toastId);
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  zone(type: string, title: string, message: string, options) {
      return this.toastrService[type](message, title, options);
  }

}
