import { SiteModel } from './site.model';
import {HrModel} from './hr.model';
import {SocketModel} from './socket.model';

export class UserModel {
  id: number;
  username: string;
  site: SiteModel;
  hr: HrModel;
  contact: HrModel;
  sockets: SocketModel[];
  token: string;

  constructor(datas ?) {
    Object.assign(this, datas);
  }
}
