import {Action} from "./action.model";
import {Detail} from "./detail.model";
import {Element} from "./element.model";
import {List} from "./list.model";

export class ListRow {
  unique_id: string;
  ressource_id: string;
  url_id: string;
  //
  details: {} = {};
  action_active: Action;
  // FILTER
  search: string;
  sort: string;
  // LOADING
  loading: boolean = false;
  loading_refresh: boolean = false;

  constructor(datas, list: List, element: Element) {
    this.update(datas, list, element);
  }

  update(datas, list: List, element: Element) {
    // RESSOURCE ID
    this.ressource_id = datas['entity_id'];
    this.url_id = datas['url_id'];
    this.unique_id = 'row.' + element.unique_id.replace('element.', '') + '#' + this.ressource_id;
    // DETAILS
    Object.keys(element.details).forEach(key => {
      this.details[element.details[key].id] = new Detail({id: element.details[key].id, type: element.details[key].type, values: datas[element.details[key].id], actions: element.details[key].actions }, this, this.ressource_id);
      // SEARCH
      if (this.details[element.details[key].id].search) {
        // INIT LIST SEARCH
        if (!list.search_columns.includes(element.details[key].id)) {
          list.search_columns.push(element.details[key].id);
        }
        // SET ROW SEARCH
        if (!this.search) {
          this.search = '';
        }
        this.search += this.details[element.details[key].id].search + ' ';
      }
      // SORT
      if (this.details[element.details[key].id].sort) {
        // INIT LIST SORT
        if (!list.sort_columns[element.details[key].id]) {
          list.sort_columns[element.details[key].id] = 0;
        }
        // SET ROW SORT
        if (!this.sort) {
          this.sort = '';
        }
        this.sort += this.details[element.details[key].id].sort + ' ';
      }
      // // ROW DETAIL ACTIONS
      // this.details[detail.id]['actions'] = [];
      // detail?.actions?.forEach(action => {
      //   // TODO
      //   let action_clone = Object.assign(new Action({}, this), action);
      //   action_clone.ressource_id = this.ressource_id;
      //   action_clone.unique_id = 'action.' + action.id + '.' + this.unique_id.replace('row.', '');
      //   // ACTION ABOUT ALL THE LINE
      //   if (element.type.type === 'list' && action.ressource['type']?.type === 'actions') {
      //     action_clone.ressource = this;
      //   }
      //   // VALUES FOR ACTION
      //   if (this.details[detail.id].values['action.' + action.id] !== undefined) {
      //     action_clone.value = this.details[detail.id].values['action.' + action.id][0];
      //   }
      //   this.details[detail.id]['actions'].push(action_clone);
      // });
    });
  }

  /* ------------------------------------------------------------------------------------------------------------------ */

  delete() {
    this.action_active = null;
    //
    Object.keys(this.details)?.forEach(item => {
      this.details[item].delete();
    });
    this.details = null;
  }
}
