import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LogService } from './log.service';
import { NavigationService } from './navigation.service';
import {AuthService, UserModel} from '../component/auth';
import {environment} from '../../environments/environment';

const API_URL = environment.api_url;

@Injectable({
    providedIn: 'root'
})
export class RestService {
    USER_TOKEN: string;

    constructor(
        private http: HttpClient,
        private log: LogService,
        private authS: AuthService,
    ) {}

    /** --------------------------------------------------------------------------------------------------------------- */

    get(url: string, auth: boolean = true): Observable<any> {
        // LOG
        this.log.warn_dev('GET ' + API_URL + url);

        // HEADERS
        let headers = null;
        if (auth) {
            this.checkToken();
            headers = new HttpHeaders({
                Authorization: `Bearer ${this.USER_TOKEN}`,
            });
        }

        // REQUEST
        return this.http.get(API_URL + url, { headers }).pipe(
            map(response => {
                return this.checkCode(response);
            }, error => {
                console.error(`GET ERROR: ${url}`, error);
            }));
    }

    post(url: string, body: any, is_file: boolean = false, auth: boolean = true): Observable<any> {
        // LOG
        this.log.warn_dev('POST ' + API_URL + url);

        // HEADERS
        let headers = null;
        if (auth) {
            this.checkToken();
            headers = new HttpHeaders({
                Authorization: `Bearer ${this.USER_TOKEN}`,
            });
        }
        let responseType;
        if (is_file) {
            responseType = 'blob';
        } else {
            responseType = 'json';
        }

        // REQUEST
        return this.http.post(API_URL + url, JSON.stringify(body), { headers, responseType }).pipe(
            map(response => {
                return this.checkCode(response);
            }, error => {
                console.error(`POST ERROR: ${url}`, error);
            }));
    }

    put(url: string, body: any): Observable<any> {
        // LOG
        this.log.warn_dev('PUT ' + API_URL + url);

        // HEADERS
        this.checkToken();
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.USER_TOKEN}`,
        });

        // REQUEST
        return this.http.put(API_URL + url, JSON.stringify(body), { headers }).pipe(
            map(response => {
                return this.checkCode(response);
            }, error => {
                console.error(`POST ERROR: ${url}`, error);
            }));
    }

    /** --------------------------------------------------------------------------------------------------------------- */

    getExternal(url: string, auth: string = null): Observable<any> {
        // LOG
        this.log.warn_dev('GET EXTERNAL ' + url + ' > ' + auth);

        // HEADERS
        let headers = null;
        if (auth) {
            headers = new HttpHeaders({
                Authorization: `Bearer ${auth}`,
            });
        }

        // REQUEST
        return this.http.get(url, { headers }).pipe(
            map(response => {
                if (this.checkCode(response)) {
                    return response;
                }
            }, error => {
                console.error(`GET ERROR: ${url}`, error);
            }));
    }

    /** --------------------------------------------------------------------------------------------------------------- */

    checkToken() {
        this.USER_TOKEN = this.authS.auth?.token;
        if (!this.USER_TOKEN) {
            this.authS.logout();
        }
    }

    checkCode(response) {
        switch (response.errors?.code) {
            case 401:
                this.authS.logout();
                break;
            case 403:
                this.authS.logout();
                break;
        }
        return response;
    }
}
