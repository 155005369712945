export class Functions {

  static slugify(str, skipSpace: boolean = false) {
    if (str === undefined || str === null) {
      return str;
    }
    if (typeof str !== 'string') {
      str = str.toString();
    }

    let map = {
      a : 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
      e : 'é|è|ê|ë|É|È|Ê|Ë',
      i : 'í|ì|î|ï|Í|Ì|Î|Ï',
      o : 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
      u : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
      c : 'ç|Ç',
      n : 'ñ|Ñ'
    };
    if (skipSpace) {
      map['-'] = '_';
    } else {
      map['-'] = ' |_';
    }

    str = str.toString().trim();
    for (let pattern in map) {
      str = str.replace(new RegExp(map[pattern], 'g'), pattern);
    }

    return str;
  }

  static ucWords(str: string) {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  static b64toBlob(b64Data, contentType= '', sliceSize= 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  /* ROUTE */
  static bindRouteWithId(route_origin: string[], id: string = null) {
    const route = Object.assign([], route_origin);
    for (let i = route.length - 1; i > 0; i--) {
      if (route[i].substr(-1) === '-') {
        route[i] = route[i] + id;
      }
    }
    return route;
  }

  /* OBSERVABLE */
  static completeObservable(observer) {
    observer.next();
    observer.complete();
  }
}
