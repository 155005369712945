import {GridBloc} from "./grid_bloc.model";
import {Element} from "./element.model";

export class Grid {
  blocs: GridBloc[];

  constructor(datas) {
    Object.assign(this, datas);
    // BLOCS
    if (datas.blocs) {
      this.blocs = [];
      datas.blocs.forEach(bloc => {
        this.blocs.push(new GridBloc(bloc));
      });
    }
  }

  /* ------------------------------------------------------------------------------------------------------------------ */

  delete() {
    //
    this.blocs?.forEach(item => {
      item.delete();
    });
    this.blocs = null;
  }
}
