import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../_services/app.service";
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from "angular-animations";

@Component({
  selector: 'manager-splash',
  templateUrl: './splash.component.html',
})
export class SplashComponent {

  constructor(
      public appS: AppService,
  ) {}
}
