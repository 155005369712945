import {SiteModule} from "./site_module.model";
import {Element} from "./element.model";
import {Detail} from "./detail.model";
import {Form} from "./form.model";
import {Type} from "./type.model";
import {GridListRow} from "./grid_list_row.model";
import {ListRow} from "./list_row.model";
import {ChildSiteModule} from "./child_site_module.model";

export class Action {
  unique_id: string;
  ressource_id: string;
  // ressource_unique_id: string;
  ressource: SiteModule|Element|Detail|GridListRow|ListRow;
  //
  id: string;
  icon_on: string;
  icon_off: string;
  text_on: string;
  text_off: string;
  title: string;
  description: string;
  button_icon: string;
  button_text: string;
  button_class: string;
  collapsed: boolean = false;
  disabled: boolean = false;
  wrapper_to_open: string;
  wrapper_to_close: string;
  order: number;
  entity_id: string;
  // MANY TO ONE
  child_site_module: ChildSiteModule;
  type: Type;
  site_module: SiteModule;
  element: Element;
  detail: Detail;
  value;
  // ONE TO ONE
  form: Form;
  // ONE TO MANY
  modal_parameters: [] = [];
  actions: Action[];
  // CUSTOM
  active: boolean = false;
  // LOADING
  loading: boolean = false;
  loading_dynamic: boolean = false;
  loading_save: boolean = false;
  // ERROR
  error_main: string;
  errors;

  constructor(datas, ressource: SiteModule|Element|Detail|GridListRow|ListRow) {
    // RESSOURCE ID
    this.ressource_id =  datas['entity_id'] ?? ressource.ressource_id;
    this.unique_id = 'action.' + datas['id'] + (this.ressource_id ? '#' + this.ressource_id : '');
    // this.ressource_unique_id = ressource.unique_id;
    this.ressource = ressource;
    // this.ressource = ressource;
    // OTHER
    this.update(datas, ressource);
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  update(datas, ressource: SiteModule|Element|Detail|GridListRow|ListRow) {
    delete datas.ressource_id;
    delete datas.ressource;
    delete datas.unique_id;
    Object.assign(this, datas);
    // TYPE
    this.type = new Type(this.type);
    if (this.child_site_module) {
      this.child_site_module = new ChildSiteModule(this.child_site_module);
    }
    // FORM
    if (this.form) {
      this.form = new Form(this.form);
    }
    // ACTIONS
    if (datas?.actions) {
      this.actions = [];
      datas.actions.forEach(action => {
        this.actions.push(new Action(action, ressource));
      });
    }
    // FUNCTION
    this.button_class += ' ' + this.getClassButton();
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  getClassButton() {
    switch (this.form?.type.type) {
      case 'new':
        // return 'btn-success';
        return 'btn-green';
      case 'update':
        // return 'btn-primary';
        return 'btn-orange';
      case 'duplicate':
        return 'btn-blue';
      case 'delete':
        // return 'btn-error';
        return 'btn-red';
      default:
        return 'btn-grey';
    }
  }

  /* ------------------------------------------------------------------------------------------------------------------ */

  delete() {
    //
    this.child_site_module = null;
    this.type = null;
    this.site_module = null;
    this.element = null;
    this.detail = null;
    //
    this.form = null;
    //
    this.actions?.forEach(item => {
      item.delete();
    });
    this.actions = null;
  }
}
