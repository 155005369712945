import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Context} from "../_models/context.model";
import {ContextMenu} from "../_models/context.menu.model";
import {Functions} from "../_functions/functions";

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  context: BehaviorSubject<Context>;
  c: Context = new Context();

  constructor() {
    this.context = new BehaviorSubject<Context>(this.c);
  }

  get(): Observable<Context> {
    return this.context;
  }
  show(event, item, id: string = null) {
    console.log(item);
    // this.app.log.log_dev(event, item, id);
    // RESET
    this.hide();
    // SHOW
    this.c.display = true;
    this.c.x = event.clientX;
    this.c.y = event.clientY;
    // NEW TAB IF ROUTE
    if (item.route) {
      let menu = new ContextMenu();
      menu.type = 'tab';
      menu.params['route'] = item.route;
      this.c.menus.push(menu);
    }
    // NEW TAB IF CHILD SITE MODULE
    else if (item.child_site_module_route && id) {
    // else if (item.child_site_module?.route && id) {
      let menu = new ContextMenu();
      menu.type = 'tab';
      menu.params['route'] = Functions.bindRouteWithId(item.child_site_module_route, id);
      this.c.menus.push(menu);
    }
    // MENUS
    item.context?.forEach(c => {
      let menu = new ContextMenu();
      menu.type = c.type;
      menu.params['params'] = c.params;
      this.c.menus.push(menu);
    });
    // DISABLE SCROLL

    // NEXT
    this.context.next(this.c);
  }
  hide() {
    this.c.display = false;
    this.c.menus = [];
    this.context.next(this.c);
  }
}
